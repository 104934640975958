<template>
  <div class="home">
    <div class="nav ds_flex al_center">
      <div class="item ds_flex al_center active">本周</div>
      <div class="item ds_flex al_center">本月</div>
    </div>
    <div class="card_list ds_flex al_center">
      <div class="item active">
        <div class="wrap">
          <p class="t1">新增VIP会员</p>
          <p class="n">130</p>
          <p class="t2">环比+3.0%<span class="icon green_icon"></span></p>
        </div>
      </div>
      <div class="item">
        <div class="wrap">
          <p class="t1">新增注册会员</p>
          <p class="n">130</p>
          <p class="t2">环比+3.0%<span class="icon green_icon"></span></p>
        </div>
      </div>
      <div class="item">
        <div class="wrap">
          <p class="t1">活跃会员</p>
          <p class="n">130</p>
          <p class="t2">环比+3.0%<span class="icon red_icon"></span></p>
        </div>
      </div>
    </div>
    <div class="title">新增VIP会员走势</div>
    <div id="main_echart" style="width: 100%;height:4rem;display:none;" class="main_echart"></div>
    <div class="title">门店成交额排行</div>
    <div class="list">
      <div class="item ds_flex al_center">
        <div class="left">1</div>
        <div class="right">
          <div class="txts ds_flex al_center">
            <div class="t1">门店A</div>
            <div class="n">38%</div>
            <div class="amount">¥300.00</div>
          </div>
          <div class="bar">
            <div class="bar_len"></div>
          </div>
        </div>
      </div>

      <div class="item ds_flex al_center">
        <div class="left">1</div>
        <div class="right">
          <div class="txts ds_flex al_center">
            <div class="t1">门店A</div>
            <div class="n">38%</div>
            <div class="amount">¥300.00</div>
          </div>
          <div class="bar">
            <div class="bar_len"></div>
          </div>
        </div>
      </div>

      <div class="item ds_flex al_center">
        <div class="left">1</div>
        <div class="right">
          <div class="txts ds_flex al_center">
            <div class="t1">门店A</div>
            <div class="n">38%</div>
            <div class="amount">¥300.00</div>
          </div>
          <div class="bar">
            <div class="bar_len"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let self = null;
export default {
  components: {
  },
  data() {
    return {
      chart: null,
    }
  },
  created() {
    self = this;
    self.initChart();
  },
  methods: {
    initChart() {
      console.log(self.$echarts);
      self.$nextTick(() => {
        self.chart = self.$echarts.init(document.getElementById('main_echart'));
        self.chart.setOption(self.getChartOption())
      })
    },
    getChartOption() {
      return {
        title: {
          text: '新增VIP会员走势',
          padding: 20
        },
        grid: {
          left: '10%',
          // top: '0',
          right: '10%',
          bottom: '10%',
        },
        tooltip: {
          // trigger: 'value'
        },
        legend: {
          // data:['销量']
        },
        xAxis: {
          data: ["3-12", "3-13", "3-14", "3-15", "3-16", "3-17"],
          boundaryGap: false,
          axisLine: { // Y轴刻度轴相关
            show: true,
            borderColor: 'red',
            lineStyle: {
              type: 'solid',
              color: '#DCDCDC',//左边线的颜色
              width: '2'//坐标线的宽度
            }
          },
          axisLabel: {
            textStyle: {
              color: '#242424',//坐标值得具体的颜色
            },
            // fontWeight: 700,
            fontSize: 13
          }
          // splitArea : {show : true},//保留网格区域
        },
        yAxis: {
          // type: 'category',
          // boundaryGap: false,
          splitLine: { show: true }, // 网格线
          axisLine: { // Y轴刻度轴相关
            show: true,
            lineStyle: {
              type: 'solid',
              color: '#DCDCDC',//左边线的颜色
              width: '2'//坐标线的宽度
            }
          },
          axisLabel: {
            textStyle: {
              color: '#242424',//坐标值得具体的颜色
            },
            // fontWeight: 700,
            fontSize: 13
          }
        },
        series: [{ // 线相关
          name: '',
          type: 'line',
          lineStyle: {
            width: 2,
            color: '#3780FF',
          },
          itemStyle: {
            color: "#3780FF"
          },
          data: [0, 20, 36, 10, 50, 40]
        }]
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.nav {
  padding: 0 0.5rem;
  height: 0.6rem;
  font-size: 0.28rem;
  color: #333;
  border-bottom: 1px solid #f7f6f8;
  .item {
    position: relative;
    padding: 0.1rem 0;
    margin-right: 0.88rem;
  }
  .active {
    color: #3780ff;
    font-size: 0.34rem;
  }
  .active::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -0.35rem;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: 0.2rem;
    height: 0.1rem;
    background: url("../../assets/img/btn_dropdown@2x.png") no-repeat center /
      100% 100%;
  }
  .active::before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    width: 0.64rem;
    height: 3px;
    background: #3780ff;
    border-radius: 4px;
  }
}
.card_list {
  padding: 0.4rem 0.3rem;
  border-bottom: 0.15rem solid #f8f8f8;
  // margin: .4rem 0;
  .item {
    flex: 1;
    -webkit-flex: 1;
    padding: 0 0.1rem;
    border-right: 1px solid #dfe5ef;
    text-align: center;
    color: #333333;
    .t1 {
      padding: 0.16rem 0 0.21rem;
      font-size: 0.24rem;
      line-height: 0.24rem;
      // color: #fff;
    }
    .n {
      margin-bottom: 0.23rem;
      font-size: 0.6rem;
      line-height: 0.6rem;
      font-weight: bold;
    }
    .t2 {
      padding-bottom: 0.18rem;
      font-size: 0.26rem;
      line-height: 0.26rem;
      .icon {
        display: inline-block;
        margin-left: 0.12rem;
        width: 0.16rem;
        height: 0.23rem;
      }
      .ac_icon_up {
        background: url("../../assets/img/icon_rise@2x.png") no-repeat center /
          100% 100%;
      }
      .ac_icon_down {
        background: url("../../assets/img/icon_reduce@2x.png") no-repeat center /
          100% 100%;
      }
      .green_icon {
        background: url("../../assets/img/icon_rise_nor@2x.png") no-repeat
          center / 100% 100%;
      }
      .red_icon {
        background: url("../../assets/img/icon_reduce_nor@2x.png") no-repeat
          center / 100% 100%;
      }
    }
    .wrap {
      // margin: 0 .1rem;
    }
  }
  .item:last-child {
    border-right: none;
  }
  .active {
    .wrap {
      border-radius: 4px;
      color: #fff;
      background: #3780ff;
    }
  }
}
.title {
  padding: 0.35rem;
  font-weight: 700;
  font-size: 0.3rem;
  color: #1e1f20;
}
.main_echart {
  // padding: .35rem;
  width: 100%;
  height: 6rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.list {
  margin: 0 0.3rem 0.3rem;
  padding: 0.5rem;
  box-shadow: 0 0 0.24rem #f5f7fb;
  .item {
    margin-bottom: 0.5rem;
    .left {
      width: 0.5rem;
    }
    .right {
      flex: 1;
      -webkit-flex: 1;
      .txts {
        margin-bottom: 0.25rem;
        color: #333333;
        .t1 {
          flex: 1;
          font-size: 0.28rem;
        }
        .n {
          flex: 1;
          font-size: 0.24rem;
        }
        .amount {
          font-size: 0.24rem;
          color: #00c7c0;
        }
      }
      .bar {
        height: 0.1rem;
        background: #f2f6fa;
        border-radius: 4px;
        .bar_len {
          width: 30%;
          height: 0.1rem;
          background: #3780ff;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
